<section id="soft-intro">
    <div class="container h-100">
        <div class="d-flex h-100 w-100 text-center">
            <h5 class="m-auto section-title" data-aos="zoom-out" data-aos-duration="1500">{{ 'contact.title' | translate}}</h5>
        </div>
    </div>
</section>

<section id="content">
    <div class="container">
        <div class="mt-4">
            <p>(+54 221) 453-3908</p>
            <p>Calle 4 #561 (B1906DVK) La Plata - Bs. As. - Argentina</p>
        </div>
    </div>
</section>