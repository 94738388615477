<section id="soft-intro">
    <div class="container h-100">
        <div class="d-flex h-100 w-100 text-center">
            <h5 class="m-auto section-title" data-aos="zoom-out" data-aos-duration="1500">{{ 'solutions.dba.title' |
                translate}}</h5>
        </div>
    </div>
</section>

<section id="content">
    <div class="container">
        <div class="text-center ml-5 mr-5">
            <img class="img-fluid mb-5" src="../../../assets/img/dba-img.svg" alt="" data-aos="fade-up"
                data-aos-duration="1500" data-aos-delay="300">
            <p>{{ 'solutions.dba.full-text' | translate}}</p>
        </div>
        
        <div class="row" style="margin-top: 10rem;">
            <div class="col-md-4">
                <div data-aos="fade-up" data-aos-delay="100">
                    <span class="material-icons service-icon">calendar_today</span>
                    <h5 class="service-title mb-1">{{ 'solutions.dba.services.24x7.title' | translate}}</h5>
                    <p class="service-text">{{ 'solutions.dba.services.24x7.text' | translate}}</p>
                </div>
            </div>
            <div class="col-md-4" data-aos="fade-up" data-aos-delay="200">
                <span class="material-icons service-icon">emoji_events</span>
                <h5 class="service-title mb-1">{{ 'solutions.dba.services.guarantee.title' | translate}}</h5>
                <p class="service-text">{{ 'solutions.dba.services.guarantee.text' | translate}}
                </p>
            </div>
            <div class="col-md-4" data-aos="fade-up" data-aos-delay="300">
                <span class="material-icons service-icon">search</span>
                <h5 class="service-title mb-1">{{ 'solutions.dba.services.audit.title' | translate}}</h5>
                <p class="service-text">{{ 'solutions.dba.services.audit.text' | translate}}</p>
            </div>
        </div>
    </div>
    <!-- <div class="animated-bg w-100 pb-0" style="height: 200px; margin-top: 10rem;"></div> -->
</section>