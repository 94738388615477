<!--==========================
    Footer
  ============================-->
<footer id="footer">

  <!-- <div class="container h-100">
    <div class="row footer-info">
      <div class="col-md-4 mb-5">
        <img src="../../../assets/img/logo.svg" alt="" class="w-50">
      </div>
      <div class="col-md-2">
      </div>
      <div class="col-md-6 p-0 text-md-right">
        <p>5987 forest hill blvd suite 203 west palm beach FL, 33415</p>
        <p>tel : 561-788-3798 / +1 (561) 788-3798</p>
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <p>Copyright &copy; 2021</p>
    </div>
  </div> -->

  <div class="container h-100">
    <div class="h-100">
      <div class="row h-100">
        <div class="col-md-6 h-100 mt-5">
          <div class="d-flex align-items-start flex-column mb-3 h-100">
            <div class="mb-auto w-100">
              <img src="../../../assets/img/logo.svg" alt="" class="w-50">
              <div class="mt-5 address">
                <p>info@work-sys.com</p>
                <p>(+54 221) 453-3908</p>
                <p>Calle 4 #561 (B1906DVK) La Plata - Bs. As. - Argentina</p>
              </div>
              
            </div>
          </div>
        </div>
        <div class="col-md-6 h-100 mt-5">
          <div class="d-flex align-items-end flex-column mb-3 h-100">
          </div>
        </div>
      </div>
    </div>
  </div>


</footer>

<!-- #footer -->