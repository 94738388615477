import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { ContactComponent } from './components/contact/contact.component';
import { DbaSupportComponent } from './components/dba-support/dba-support.component';
import { Error404Component } from './components/error404/error404.component';
import { HomeComponent } from './components/home/home.component';
import { MobileDevelopmentComponent } from './components/mobile-development/mobile-development.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { ProductsComponent } from './components/products/products.component';
import { SoftwareDevelopmentComponent } from './components/software-development/software-development.component';


const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'navbar', component: NavbarComponent },
  { path: 'software', component: SoftwareDevelopmentComponent },
  { path: 'mobile', component: MobileDevelopmentComponent },
  { path: 'dba', component: DbaSupportComponent },
  { path: 'about', component: AboutUsComponent },
  { path: 'products', component: ProductsComponent },
  {
    path:"",
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path:"**",
    component:Error404Component
}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
