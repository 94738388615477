import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  public CURRENTLANG : string;

  constructor() {
    this.CURRENTLANG = 'es';
   }


}