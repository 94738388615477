<section id="soft-intro">
    <div class="container h-100">
        <div class="d-flex h-100 w-100 text-center">
            <h5 class="ml-auto mr-auto mt-5 section-title" data-aos="zoom-out" data-aos-duration="1500">{{
                'header.products' | translate}}</h5>
        </div>
    </div>
</section>

<section id="content">
    <div class="container">
        <div class="row">
            <div class="col-md-6 p-5" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                <img class="mw-100" src="../../../assets/img/products/ticketal-img.svg" alt="">
            </div>
            <div class="col-md-6 p-5" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300">
                <img class="img-fluid d-block" src="../../../assets/img/products/ticketal-logo.svg" alt="">
                <img class="img-fluid d-block mt-5 mb-5" src="../../../assets/img/products/ticketal-hr.svg" alt="">
                <p>{{ 'products.ticketal' | translate}}</p>
                <a href="https://www.ticketal.com.ar/" target="blank"><button type="button" class="btn more-info-btn mt-5">{{ 'intro.more' | translate}}</button></a>
            </div>
        </div>
        <hr class="mt-5">
        <div class="row" style="margin-top: 5rem;">
            <div class="col-md-6 p-5" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                <img class="mw-100" src="../../../assets/img/products/trucks-img.svg" alt="">
            </div>
            <div class="col-md-6 p-5" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300">
                <img class="img-fluid d-block" src="../../../assets/img/products/trucks-logo.svg" alt="">
                <img class="img-fluid d-block mt-5 mb-5" src="../../../assets/img/products/trucks-hr.svg" alt="">
                <p>{{ 'products.trucks' | translate}}</p>
                <a href="https://trucks-net.com/" target="blank"><button type="button" class="btn more-info-btn mt-5">{{ 'intro.more' | translate}}</button></a>
            </div>
        </div>
        <hr class="mt-5">
        <div class="row" style="margin-top: 5rem;">
            <div class="col-md-6 p-5" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                <img class="mw-100" src="../../../assets/img/products/bratec-img.svg" alt="">
            </div>
            <div class="col-md-6 p-5" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300">
                <img class="img-fluid d-block" src="../../../assets/img/products/bratec-logo.svg" alt="">
                <img class="img-fluid d-block mt-5 mb-5" src="../../../assets/img/products/bratec-hr.svg" alt="">
                <p>{{ 'products.bratec' | translate}}</p>
                <a href="https://www.skytech29.com/" target="blank"><button type="button" class="btn more-info-btn mt-5">{{ 'intro.more' | translate}}</button></a>
            </div>
        </div>
        <hr class="mt-5">
        <div class="row" style="margin-top: 5rem;">
            <div class="col-md-6 p-5" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                <img class="mw-100" src="../../../assets/img/products/taxi-img.svg" alt="">
            </div>
            <div class="col-md-6 p-5" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300">
                <img class="img-fluid d-block" src="../../../assets/img/products/taxi-logo.svg" alt="">
                <img class="img-fluid d-block mt-5 mb-5" src="../../../assets/img/products/taxi-hr.svg" alt="">
                <p>{{ 'products.taxi' | translate}}</p>
                <a href="http://taxidron.ar/" target="blank"><button type="button" class="btn more-info-btn mt-5">{{ 'intro.more' | translate}}</button></a>
            </div>
        </div>
        <hr class="mt-5">
    </div>
</section>