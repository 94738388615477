<section id="soft-intro">
    <div class="container h-100">
        <div class="d-flex h-100 w-100 text-center">
            <h5 class="m-auto section-title" data-aos="zoom-out" data-aos-duration="1500">{{ 'solutions.soft.title' | translate}}</h5>
        </div>
    </div>
</section>

<section id="content">
    <div class="container">
        <div class="text-center ml-5 mr-5">
            <p>{{ 'solutions.soft.text' | translate}}</p>
        </div>
        <div style="margin-top: 8rem;">
            <h6>{{ 'solutions.soft.other.title' | translate}}</h6>
            <ul>
                <li><span class="material-icons mr-2 align-middle">check</span>{{ 'solutions.soft.other.1' | translate}}</li>
                <li><span class="material-icons mr-2 align-middle">check</span>{{ 'solutions.soft.other.2' | translate}}</li>
                <li><span class="material-icons mr-2 align-middle">check</span>{{ 'solutions.soft.other.3' | translate}}</li>
                <li><span class="material-icons mr-2 align-middle">check</span>{{ 'solutions.soft.other.4' | translate}}</li>
                <li><span class="material-icons mr-2 align-middle">check</span>{{ 'solutions.soft.other.5' | translate}}</li>
            </ul>
        </div>
    </div>
</section>