import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dba-support',
  templateUrl: './dba-support.component.html',
  styleUrls: ['./dba-support.component.scss']
})
export class DbaSupportComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    window.scrollTo(0, 0)
  }

}
