<!--==========================
  Header
  ============================-->
  <header id="header" class="header-scrolled">
  
    <nav class="navbar navbar-expand-lg navbar-dark">
      <div class="container">
  
        <a routerLink="/home" routerLinkActive="active"><img src="../assets/img/logo.svg" alt=""></a>
  
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
          aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav ml-auto text-uppercase">
            <li class="nav-item active"><a routerLink="/about" routerLinkActive="active">{{ 'header.about' | translate}}</a></li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {{ 'header.our-work' | translate}}
              </a>
              <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                <a class="dropdown-item" routerLink="/software">{{ 'solutions.soft.title' | translate}}</a>
                <a class="dropdown-item" routerLink="/mobile">{{ 'solutions.mobile.title' | translate}}</a>
                <a class="dropdown-item" routerLink="/dba">{{ 'solutions.dba.title' | translate}}</a>
              </div>
            </li>
            <li class="nav-item active"><a routerLink="/products" routerLinkActive="active">{{ 'header.products' | translate}}</a></li>
            <li>
              <div class="pl-2">
                <a href="javascript:;" (click)="cambiarLenguaje('en')" class="d-inline p-1" [class.activeLang]="currentLang === 'en' ">EN</a>
                <a href="javascript:;" (click)="cambiarLenguaje('es')" class="d-inline p-1" [class.activeLang]="currentLang === 'es' ">ES</a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  
  </header><!-- #header -->