<div class="dark-bg">

  <!--==========================
    Intro Section
    ============================-->
  <section id="intro" class="clearfix" style="height: 90vh;">
    <div class="container h-100">
      <div class="row justify-content-center pt-5">
        <div class="col-md-2 col-1 d-none d-md-block">
          <div class="d-flex align-items-start flex-column">
            <div class="mb-5 p-2"><img class="img-1 animated" src="../../../assets/img/intro-img/img-1.svg" alt=""
                data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100"></div>
            <div class="p-2 m-5"><img class="img-1 animated2" src="../../../assets/img/intro-img/img-2.svg" alt=""
                data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200"></div>
            <div class="p-2 mt-5"><img class="img-1 animated" src="../../../assets/img/intro-img/img-3.svg" alt=""
                data-aos="fade-up" data-aos-duration="1000" data-aos-delay="300"></div>
          </div>
        </div>
        <div class="col-md-8 col-10">
          <div class="text-center h-75 intro-info">
            <div>
              <div class="mb-5" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200">
                <h2 class="pb-2 w-100" >{{ 'intro.simpler' | translate}}</h2>
                  <p class="subtitle">{{ 'intro.subtitle' | translate}}</p>
                  <button routerLink="/about" type="button" class="btn read-more-btn">{{ 'intro.read-more' | translate}}</button>
              </div>
              <img src="../../../assets/img/intro-img.svg" alt="" class="img-fluid" data-aos="fade-up"
                data-aos-duration="1000" data-aos-delay="400">
            </div>
          </div>
        </div>
        <div class="col-md-2 d-none d-md-block">
          <div class="d-flex align-items-end flex-column">
            <div class="p-2 mt-5 mb-5"><img class="img-1 animated2" src="../../../assets/img/intro-img/img-4.svg" alt=""
                data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100"></div>
            <div class="p-2 m-5"><img class="img-1 animated" src="../../../assets/img/intro-img/img-5.svg" alt=""
                data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200"></div>
          </div>
        </div>
      </div>
    </div>
  </section><!-- #intro -->

  <main id="main">


    <!--==========================
        about Section
        ============================-->

    <section id="about" class="pb-8 pt-5">
      <div class="container" data-aos="fade-right" data-aos-duration="1000">
        <div>
          <h5 class="text-dark">{{ 'about.title' | translate}}</h5>
          <img class="mb-4 mt-4" src="../../../assets/img/hr-custom.svg" alt="">
          <p class="text-dark">{{ 'about.text' | translate}}</p>
        </div>

        <div class="row mt-5">
          <div class="col-md-4">
            <a routerLink="/software">
              <div class="box text-center" data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000">
                <img class="w-25 mb-4" src="../../../assets/img/soft.svg" alt="">
                <h6 class="mb-3">{{ 'solutions.soft.title' | translate}}</h6>
                <p>{{ 'solutions.soft.text' | translate}}</p>
              </div>
            </a>
          </div>
          <div class="col-md-4">
            <a routerLink="/mobile">
              <div class="box text-center" data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000">
                <img class="w-25 mb-4" src="../../../assets/img/mobile.svg" alt="">
                <h6>{{ 'solutions.mobile.title' | translate}}</h6>
                <p>{{ 'solutions.mobile.text' | translate}}</p>
              </div>
            </a>
          </div>
          <div class="col-md-4">
            <a routerLink="/dba">
              <div class="box text-center" data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000">
                <img class="w-25 mb-4" src="../../../assets/img/db.svg" alt="">
                <h6>{{ 'solutions.dba.title' | translate}}</h6>
                <p>{{ 'solutions.dba.text' | translate}}</p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>

    <section id="home-products">
      <div class="container">
        <div class="text-center" data-aos="fade-up">
          <h5 class="text-dark">{{ 'intro.some-solutions' | translate}}</h5>
          <img class="mb-5 mt-2" src="../../../assets/img/hr-custom.svg" alt="">
        </div>
        <div class="row d-flex justify-content-between">
          <div class="col-md-3 text-center p-3" data-aos="fade-up" data-aos-delay="100">
            <img class="img-fluid" src="../../../assets/img/home-products/trucks-logo.svg" alt="">
          </div>
          <div class="col-md-3 text-center p-3" data-aos="fade-up" data-aos-delay="200">
            <img class="img-fluid" src="../../../assets/img/home-products/ticketal-logo.svg" alt="">
          </div>
          <div class="col-md-3 text-center p-3" data-aos="fade-up" data-aos-delay="300">
            <img class="img-fluid" src="../../../assets/img/home-products/bratec-logo.svg" alt="">
          </div>
          <div class="col-md-3 text-center p-3" data-aos="fade-up" data-aos-delay="400">
            <img class="img-fluid" src="../../../assets/img/home-products/taxi-logo.svg" alt="">
          </div>
        </div>
        <div class="m-5 text-center" data-aos="fade-up">
          <button routerLink="/products" type="button" class="btn read-more-btn">{{ 'intro.more' | translate}}</button>
        </div>
      </div>
    </section>


    <!--==========================
          Brands Section
          ============================-->

    <section id="brands" class="section-bg-light">
      <div class="container h-100 d-flex">
        <div class="row align-self-center">

          <div class="col-lg-2 col-md-4 col-6">
            <img src="../../../assets/img/brands/telmex.svg" class="img-fluid" alt="" data-aos="zoom-in">
          </div>

          <div class="col-lg-2 col-md-4 col-6">
            <img src="../../../assets/img/brands/gba.svg" class="img-fluid" alt="" data-aos="zoom-in"
              data-aos-delay="100">
          </div>

          <div class="col-lg-2 col-md-4 col-6">
            <img src="../../../assets/img/brands/inti.svg" class="img-fluid" alt="" data-aos="zoom-in"
              data-aos-delay="200">
          </div>

          <div class="col-lg-2 col-md-4 col-6">
            <img src="../../../assets/img/brands/datik.svg" class="img-fluid" alt="" data-aos="zoom-in"
              data-aos-delay="300">
          </div>

          <div class="col-lg-2 col-md-4 col-6">
            <img src="../../../assets/img/brands/senasa.svg" class="img-fluid" alt="" data-aos="zoom-in"
              data-aos-delay="400">
          </div>

          <div class="col-lg-2 col-md-4 col-6">
            <img src="../../../assets/img/brands/lbel.svg" class="img-fluid" alt="" data-aos="zoom-in"
              data-aos-delay="500">
          </div>

        </div>
      </div>
    </section>




  </main>



</div>