<section id="soft-intro">
    <div class="container h-100">
        <div class="d-flex h-100 w-100 text-center">
            <h5 class="m-auto section-title" data-aos="zoom-out" data-aos-duration="1500">{{ 'about.title' | translate}}</h5>
        </div>
    </div>
</section>

<section id="content">
    <div class="container">
        <div class="row justify-content-between">
            <div class="col-md-4 p-4">
                <img class="img-fluid" src="../../../assets/img/about-img.png" alt="" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300">
            </div>
            <div class="col-md-7 p-4">
                <p data-aos="fade-up" data-aos-duration="1500" data-aos-delay="600">{{ 'about.full-text' | translate}}</p>
            </div>
        </div>
    </div>
</section>
<section id="why-us">
    <div class="container">
        <div class="p-4">
            <h5>{{ 'about.why-us.title' | translate}}</h5>
            <hr>
            <p>{{ 'about.why-us.text' | translate}}</p>
        </div>
    </div>
</section>