<section>
    <div class="container vh-100">
        <div class="d-flex justify-content-center h-100 align-items-center">
            <div class="text-center">
                <img src="../../../assets/img/404.svg" alt="">
                <h5 class="mt-4">{{ '404.message' | translate}}</h5>
                <a routerLink="/home">{{ '404.go-home' | translate}}</a>
            </div>
        </div>
    </div>
</section>
