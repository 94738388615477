import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from '../../services/config.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(
    private config: ConfigService,
    public translate: TranslateService
  ) { }

  currentLang: string

  ngOnInit(): void {
    this.currentLang = this.config.CURRENTLANG;
  }

  cambiarLenguaje(lang){
    this.translate.use(lang);
    this.currentLang = this.translate.currentLang;
}

}
