import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from '../../services/config.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(
    private config: ConfigService,
    public translate: TranslateService) {

  }

  currentLang: string

  ngOnInit(): void {
    this.currentLang = this.config.CURRENTLANG;
  }

}
