<section id="soft-intro">
    <div class="container h-100">
        <div class="d-flex h-100 w-100 text-center">
            <h5 class="m-auto section-title" data-aos="zoom-out" data-aos-duration="1500">{{ 'solutions.mobile.title' | translate}}</h5>
        </div>
    </div>
</section>

<section id="content">
    <div class="container">
        <div class="text-center ml-5 mr-5">
            <img class="img-fluid mb-5" src="../../../assets/img/mobile-img.svg" alt="" data-aos="fade-up"
            data-aos-duration="1500" data-aos-delay="300">
            <p>{{ 'solutions.mobile.text' | translate}}</p>
        </div>
    </div>
</section>