import { Component, AfterViewInit, OnInit } from '@angular/core';
import { NgwWowService } from 'ngx-wow';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from './services/config.service';

declare var jQuery: any;
declare var $: any;




@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'worksys';

  constructor(
    private config: ConfigService,
    public translate: TranslateService,
    private wowService: NgwWowService) {

    this.translate.addLangs(['es', 'en']);
    this.translate.setDefaultLang(config.CURRENTLANG);

    this.wowService.init();
  }

  currentLang: string = 'en'




  ngOnInit() {


    


    // scroll for nav bar .scroll-to css
    $('.navbar a, .mobile-nav a, .scrollto').on('click', function () {
      if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
        var target = $(this.hash);
        if (target.length) {
          var top_space = 0;

          if ($('#header').length) {
            top_space = $('#header').outerHeight();

            if (!$('#header').hasClass('header-scrolled')) {
              top_space = top_space - 40;
            }
          }

          $('html, body').animate({
            scrollTop: target.offset().top - top_space
          }, 1500, 'easeInOutExpo');

          if ($(this).parents('.main-nav, .mobile-nav').length) {
            $('.main-nav .active, .mobile-nav .active').removeClass('active');
            $(this).closest('li').addClass('active');
          }

          if ($('body').hasClass('mobile-nav-active')) {
            $('body').removeClass('mobile-nav-active');
            $("#mobile-menu").removeClass('d-none');
            $("#mobile-close").addClass('d-none');
            $('.mobile-nav-overly').fadeOut();
          }
          return false;
        }
      }
    });




    // Navigation active state
    var nav_sections = $('section');
    var main_nav = $('.main-nav, .mobile-nav');
    var main_nav_height = $('#header').outerHeight();


    $(window).on('scroll', function () {
      var cur_pos = $(this).scrollTop();

      nav_sections.each(function () {
        var top = $(this).offset().top - main_nav_height,
          bottom = top + $(this).outerHeight();

        if (cur_pos >= top && cur_pos <= bottom) {
          main_nav.find('li').removeClass('active');
          main_nav.find('a[href="#' + $(this).attr('id') + '"]').parent('li').addClass('active');
        }
      });
    });

    $('.menu-box').click(function () {
      var target = '#' + $(this).data('target');
      var btnTarget = '#btn-' + $(this).data('target');

      $(target).parent().children().addClass('d-none');
      $(target).removeClass('d-none');
      $(target).find('div').removeClass('d-none');

      $('.menu-box').removeClass('active');
      $(btnTarget).addClass('active');



    })

    

  }



  



}
